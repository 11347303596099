import { copyToClipboard } from "../functions";
export class SocialShare { 
    constructor (selecteur, url, title, msg, hashtags, options = {}) {
        this.selecteur = selecteur;
        this.url = url;
        this.msg = msg;
        this.hashtags = hashtags;
        this.title = title;
        this.sizes = {
            width:570,
            height:450
        };
        this.options = Object.assign({
            icons: [
                {
                    name: 'facebook',
                    url: `https://www.facebook.com/share.php?u=${ this.url ? this.url : encodeURI(window.location.href) }`,
                    icoVariant: 'fab',
                    ico: 'fa-facebook-f',
                },
                {
                    name: 'linkedin',
                    url: `https://www.linkedin.com/sharing/share-offsite/?url=${ this.url ? this.url : encodeURI(window.location.href) }`,
                    icoVariant: 'fab',
                    ico: 'fa-linkedin-in',
                },
                {
                    name: 'twitter',
                    url: `http://twitter.com/share?&url=${ this.url ? this.url : encodeURI(window.location.href) }&text=${this.msg ? this.msg : "Partage d'un article" }&hashtags=${this.hashtags ? this.hashtags : "" }`,
                    icoVariant: 'fab',
                    ico: 'fa-x-twitter',
                },
                {
                    name: 'whatsapp',
                    url: `https://api.whatsapp.com/send?text=${this.msg ? this.msg : "Partage d'un article" } ${ this.url ? this.url : encodeURI(window.location.href) }`,
                    icoVariant: 'fa-brands',
                    ico: 'fa-whatsapp',
                },
                {
                    name: 'copy',
                    icoVariant: 'fa-solid',
                    ico: 'fa-link',
                },
                {
                    name: 'email',
                    url: `mailto:?&subject=${this.title ? this.title : "Regardez cet article" }&body=${this.msg ? this.msg : "Partage d'un article" }: ${ this.url ? this.url : encodeURI(window.location.href) }`,
                    icoVariant: 'fa-regular',
                    ico: 'fa-envelope',
                },
            ]
        }, options);
        this.init()
    }

    init() {
        let i = 0;
        if(this.selecteur){
            for (const value of this.selecteur.children) {
                if (this.options.icons[i]) {
                    value.firstElementChild.classList.add(this.options.icons[i].icoVariant)
                    value.firstElementChild.classList.add(this.options.icons[i].ico)
                }
                if (value.dataset.name === 'copy') { 
                    value.addEventListener('click', (e) => {
                        copyToClipboard(this.url ? this.url : encodeURI(window.location.href))
                    })
                } else {
                    value.href = this.options.icons[i].url
                }
                i++
            }
        }
    
    }
}

