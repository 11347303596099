/**
 * Tronque une chaîne donnée en fonction des paramètres spécifiés.
 *
 * @param {string} str - La chaîne d'entrée
 * @param {number} [firstCharCount=str.length] - Le nombre de caractères à inclure depuis le début de la chaîne
 * @param {number} [endCharCount=0] - Le nombre de caractères à inclure depuis la fin de la chaîne
 * @param {number} [dotCount=3] - Le nombre de points à ajouter entre les parties tronquées
 * @return {string} La chaîne tronquée
 */
export function truncateString(str, firstCharCount = str.length, endCharCount = 0, dotCount = 3) {

    if (str.length <= firstCharCount + endCharCount) {
      return str; // No truncation needed
    }

    const firstPortion = str.slice(0, firstCharCount);
    const endPortion = str.slice(-endCharCount);
    const dots = '.'.repeat(dotCount);

    return `${firstPortion} ${dots} ${endPortion}`;
}

/**
 * Copie le texte donné dans le presse-papiers en utilisant l'API du presse-papiers du navigateur si disponible, sinon utilise l'astuce du "zone de texte cachée hors de la vue".
 *
 * @param {string} textToCopy - Le texte à copier dans le presse-papiers
 * @return {undefined} 
 */
export function copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(textToCopy);
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
    alert(`Url de la page copié: ${textToCopy}` );
};