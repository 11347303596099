export default class Dropdown {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign({}, options);
        this.init();
    }
    init() {
        for (const element of this.selector.children) {
            if (element.tagName == 'BUTTON') {
                element.addEventListener('click', () => {
                    this.toggle(element);
                })
            }
        }
    }

    toggle = (ele) => {

        const buttonOpen = document.querySelector('.dropdown__stack-1 .dropdown__button.open')

        if (!ele.classList.contains('open')) {
            ele?.classList.toggle('open');
            ele?.nextElementSibling.classList.toggle('open');

        }

        if (window.innerWidth > 860) {
            buttonOpen?.classList.toggle('open');
            buttonOpen?.nextElementSibling.classList.toggle('open');
        }
        
    }
}