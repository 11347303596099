import { Slider } from '../class/Sliders';

if (document.querySelector('.topBarre')) {

    addEventListener('load', () => {
        
        const barre = document.querySelector('.topBarre');
        const menu = document.querySelector('.menu');
        const main = document.querySelector('main');
        let dropdownItems = document.querySelector('.dropdown__stack-1').offsetHeight;
        let heightMenu = menu.offsetHeight;
        const HeightBarre = barre.offsetHeight;

        let lastScrollTop = document.documentElement.scrollTop;
        document.documentElement.style.setProperty('--heightMenu', heightMenu + 'px');
        main.style.marginTop = `${dropdownItems - 5 }px`;
        // Cache la barreTop de navigation au click sur l'icone
        for (const children of barre.children) {
            if (children.className.includes('topBarre__icon')) {
                const btn = children;
                
                btn.addEventListener('click', () => {
                    menu.style.transform = `translateY(-${HeightBarre}px)`;
                    main.style.marginTop = `-${HeightBarre}px`;
                    heightMenu = menu.offsetHeight;
                    document.documentElement.style.setProperty('--heightMenu', heightMenu - HeightBarre + 'px');
                });
            }
        }

        //regarde si je redimensionne la fenetre
        window.addEventListener('resize', () => {
            dropdownItems = document.querySelector('.dropdown__stack-1').offsetHeight;
            window.addEventListener('scroll', () => {
                if (window.innerWidth < 860) {
                    const scrollTopPosition = document.documentElement.scrollTop;
                    if ( scrollTopPosition > lastScrollTop && scrollTopPosition > 250 ) {
                        menu.style.transform = `translateY(-${HeightBarre}px)`;
                    } else if (scrollTopPosition < lastScrollTop) {
                        menu.style.transform = `translateY(0px)`;
                    }
                    lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
                }
            });
            if (window.innerWidth > 861) { 
                dropdownItems = document.querySelector('.dropdown__stack-1').offsetHeight;
                main.style.marginTop = `${dropdownItems - 5 }px`;
            } else {
                main.style.marginTop = `0px`;
            }     
        });
    })

}

document.querySelectorAll('.sliders__topBarre').forEach((element) => {
    new Slider(element, {
        pagination: false,
        arrows: false,
        autoplay: false,
    });
});
