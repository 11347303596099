// import './pristine.min.js'

// window.onload = function () {
//     let selectors = []

//     if (document.getElementById("formConnexion")) {
//         const selector = document.getElementById("formConnexion")
//         selectors.push(selector);
//     }
//     if (document.getElementById("formInscription")) {
//         const selector = document.getElementById("formInscription")
//         selectors.push(selector);
//     }

//     selectors.forEach(selector => {
//         const pristine = new Pristine(selector);
//         selector.addEventListener('submit', function (e) {
//             e.preventDefault();
//             const valid = pristine.validate();
//         });
//     })


// };
import JustValidate from "just-validate";
import { lang, fields } from "./just-validate-config";

const forms = document.querySelectorAll(".forms");
forms.forEach(forms => {
    const validator = new JustValidate(forms, undefined, lang);
    validator.setCurrentLocale('French');

    const elements = forms.querySelectorAll(".email, .password, .strongPassword, .name, .checkbox, .message, .phone");

    elements.forEach(selector => {
        switch (true) {
            case selector.classList.contains("name"):
                validator.addField(selector, fields.name);
                break;
            case selector.classList.contains("email"):
                validator.addField(selector, fields.email);
                break;
            case selector.classList.contains("phone"):
                validator.addField(selector, fields.phone);
                break;
            case selector.classList.contains("checkbox"):
                validator.addField(selector, fields.checkbox, {
                    errorsContainer: '#checkbox-errors-container',
                });
                break;
            case selector.classList.contains("password"):
                validator.addField(selector, fields.required);
                break;
            case selector.classList.contains("strongPassword"):
                validator.addField(selector, fields.strongPassword);
                break;
            case selector.classList.contains("message"):
                validator.addField('.message', fields.message);
                break;
            default:
                break;
        }
    });


});




