import { SocialShare } from '../class/SocialShare';
import { truncateString } from '../functions';

if (document.querySelectorAll('.cards__article')) {
    document.querySelectorAll('.cards__article').forEach((element) => {
        const title = element.querySelector('h2').innerHTML;
        const linkArticle = element.querySelector('.cards__content .link').href;
        const tagsArticle = element.querySelector('.cards__badges').children;
        const descriptionArticle = element.querySelector('.cards__footer p').innerHTML;
        const tags = []
        for (const tag of tagsArticle) { 
            tags.push(tag.firstElementChild.innerHTML)
        }
        new SocialShare(
            element.children[2].children[2].firstElementChild,
            linkArticle,
            title,
            truncateString(descriptionArticle, 25, 10),
            tags
        );
    });
}

if (document.querySelectorAll('.social-media--share').length <= 1) {
    new SocialShare(document.querySelector('.social-media--share'));
}