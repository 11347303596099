
import Splide from '@splidejs/splide';

export class Slider { 
    constructor (selecteur, options = {}) {
        this.selecteur = selecteur;
        this.options = Object.assign({
            type: "loop", 
            autoplay: true,
            pagination: true,
            arrows: true,
            perPage: 1,
            gap: '1rem',
            breakpoints: '',
            padding: '',
            rewind: '',
            rewindSpeed: '',
            heightRatio: '',
            cover: '',
            dragMinThreshold: '',
            fixedWidth: '',
            fixedHeight: '',
            sync:'',
        }, options);
        this.init()
        this.selecteur._slider = this
    }

    init() {
        if (this.options.sync) {
            const main = new Splide(this.selecteur, {
                type: this.options.type,
                autoplay: this.options.autoplay,
                pagination: this.options.pagination,
                arrows: this.options.arrows,
                perPage: this.options.perPage,
                gap: this.options.gap,
                breakpoints: this.options.breakpoints,
                padding: this.options.padding,
                rewind: this.options.rewind,
                rewindSpeed: this.options.rewindSpeed,
                heightRatio: this.options.heightRatio,
                cover: this.options.cover,
                dragMinThreshold: this.options.dragMinThreshold,
                fixedWidth: this.options.fixedWidth,
                fixedHeight: this.options.fixedHeight
            }).mount();    
        } else {
            // console.log(this.selecteur);
            new Splide(this.selecteur, {
                type: this.options.type,
                autoplay: this.options.autoplay,
                pagination: this.options.pagination,
                arrows: this.options.arrows,
                perPage: this.options.perPage,
                gap: this.options.gap,
                breakpoints: this.options.breakpoints,
                padding: this.options.padding,
                rewind: this.options.rewind,
                rewindSpeed: this.options.rewindSpeed,
                heightRatio: this.options.heightRatio,
                cover: this.options.cover,
                dragMinThreshold: this.options.dragMinThreshold,
                fixedWidth: this.options.fixedWidth,
                fixedHeight: this.options.fixedHeight
            }).mount();    
        }
            
        
        // main.sync( thumbnails );
        // main.mount();
        // thumbnails.mount();
    }
}
