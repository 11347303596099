import '@innovdata/preline'
import Splide from '@splidejs/splide'
import 'leaflet/dist/leaflet.js'

import './components/topBarre'
import './components/backTop'
import './components/sliders'
import './components/social-share'
import './components/dropdown'
import './forms'

import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;



    const openButtons = document.querySelectorAll(`[data-toggle-button*="toggle"]`);
    const menu = document.querySelector('.menu__nav');
const searchContainer = document.querySelector('.navigation__search-container');
    const html = document.querySelector('html');

    const toggle = (ele) => {
        ele?.classList.toggle('open');
    }

    openButtons.forEach(openButton => {
        openButton?.addEventListener('click', () => {
            switch (openButton.dataset.toggleButton) {
                case 'toggle-menu':
                    toggle(menu)
                    html.style.overflowY = menu.classList.contains('open') ? 'hidden' : 'auto';
                    break;
                
                case 'toggle-search':
                    toggle(searchContainer)
                    break;
        
                default:
                    break;
            }
            
        })
    });
    
