export const fields = {
    required: [
        {
            rule: 'required',
            errorMessage: 'Le champ est obligatoire',
        },
    ],
    name: [
        {
            rule: 'required',
            errorMessage: 'Le nom est requis',
        },
        {
            // Cette expression régulière permettra de vérifier si une chaîne de caractères contient uniquement des lettres alphabétiques, y compris les noms composés avec un tiret ou un espace entre les mots.
            rule: 'customRegexp',
            value: /^[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/,
            errorMessage: 'Des caractères alphabétiques sont demandés',
        },
        {
            rule: 'minLength',
            value: 3,
            errorMessage: 'Longueur minimale de 3 caractères',
        },
        {
            rule: 'maxLength',
            value: 15,
            errorMessage: 'Longueur maximale de 15 caractères',
        },
    ],
    email: [
        {
            rule: 'required',
            errorMessage: `L'email est requis`,
        },
        {
            rule: 'email',
            errorMessage: `L'email est invalide`,
        },
    ],
    phone: [
        {
            rule: 'required',
            errorMessage: `Le téléphone est requis`,
        },
        {
            rule: 'customRegexp',
            value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            errorMessage: `Le téléphone est requis`,
        },
    ],
    password:[
        {
            rule: 'required',
            errorMessage: 'Mot de passe requis',
        },
        {
            rule: 'password',
            errorMessage: 'Le mot de passe doit contenir au minimum huit caractères, au moins une lettre et un chiffre '
        },
    ],
    strongPassword:[
        {
            rule: 'required',
            errorMessage: 'Mot de passe requis',
        },
        {
            rule: 'strongPassword',
            errorMessage: 'Le mot de passe doit contenir au minimum huit caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial. '
        },
    ],
    checkbox:[
        {
            rule: 'required',
            errorMessage: 'La validation est requise',
        },
        {
            rule: 'strongPassword',
            errorMessage: 'Le mot de passe doit contenir au minimum huit caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial. '
        },
    ],
    message:[
        {
            validator: (value) => {
                return value !== undefined && value.length > 3;
            },
            errorMessage: 'Le message doit contenir plus de 3 lettres. ',
        },
    ],

}

export const lang = [
    {
        // key: 'The field is required',
        // dict: {
        //     French: 'Le champ est obligatoire',
        // },
    },

];
