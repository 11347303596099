import { Slider } from "../class/Sliders";

document.querySelectorAll(`[data-slider]`).forEach((element) => {
    const value = element.dataset['slider'];
    switch (value) {
        case 'hide':
            new Slider(element, {
                pagination: false,
                arrows: false,
            });
            break;
        
        case 'articles':
            new Slider(element, {
                perPage: 3,
                breakpoints: {
                    1280: {
                        perPage: 1,
                        gap    : '0rem',
                    },
                },
            });
            break;
        
        case 'accessories':
            new Slider(element, {
                autoplay: false,
                gap    : '2rem',
                perPage: 3,
                perMove: 1,
                padding: { right: '15rem' },
                type   : 'loop',
                breakpoints: {
                    1535: {
                        perPage: 3,
                        padding: { right: '10rem' },
                    },
                    1024: {
                        perPage: 3,
                        padding: { right: '8rem' },
                    },
                    768: {
                        perPage: 2,
                        padding: 0,
                    },
                    640: {
                        perPage: 1,
                        padding: 0,
                    },
                },
            });
            break;
        
        case 'tabs':
            new Slider(element, {
                autoplay: false,
                pagination: false,
                arrows: false,
                perPage: 3,
                breakpoints: {
                    1280: {
                        perPage: 1,
                        gap    : '0rem',
                    },
                },
            });
            break;
        

        case 'product':
            new Slider(element, {
                type       : 'fade',
                heightRatio: 0.8,
                arrows     : false,
                cover      : true,
                fixedWidth: '100%',
                fixedHeight: '600px',
                autoplay: true,
                rewind     : true,
                rewindSpeed: 1000,

                breakpoints : {

                    640: {
                        fixedWidth: '100%',
                        fixedHeight: 250,
                        heightRatio: 0.8,
                    },

                    1024: {
                        heightRatio: 0.8,
                        fixedHeight: 350,
                    },

                    1280: {
                        fixedHeight: '500px',
                    },
                },
            });
            break;
        
            case 'thumbnail-slider':
                new Slider(element, {
                    rewind          : true,
                    perPage         : 3,
                    heightRatio     : 0.3,
                    pagination      : false,
                    cover           : true,
                    arrows          : false,
                    
                    dragMinThreshold: {
                        mouse: 4,
                        touch: 10,
                    },
                    breakpoints : {
                        640: {
                            fixedWidth: '100%',
                            fixedHeight: 250,
                        },
                        1024: {
                            perPage: 2, 
                            fixedWidth: '50%',
                        },
                        1024: {
                            perPage: 2, 
                            fixedWidth: '50%',
                        },
    
                    },
                });
                break;

        default:
            new Slider(element);
            break;
    }
});